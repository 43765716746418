// export const APP_BASE_PATH = 'http://localhost:4000/';
// export const LOGIN_BASE_PATH = 'http://localhost:4000/api/login';
// export const USERS_BASE_PATH = 'http://localhost:4000/api/users';
// export const CONTRACTORS_BASE_PATH = 'http://localhost:4000/api/contractors';
// export const EXCEL_BASE_PATH = 'http://localhost:4000/api/excel';
// export const ITEM_BASE_PATH = 'http://localhost:4000/api/items';  
// export const EMAIL_BASE_PATH = 'http://localhost:4000/api/email';
// export const STATUS_BASE_PATH = 'http://localhost:4000/api/status';
// export const RECORDS_BASE_PATH = 'http://localhost:4000/api/records';
// export const INVOICE_BASE_PATH = 'http://localhost:4000/api/invoice';
// export const EXCEPTION_BASE_PATH = 'http://localhost:4000/api/Exception';
// export const CREW_BASE_PATH = 'http://localhost:4000/api/crew';
// export const OCCUPANCY_BASE_PATH = 'http://localhost:4000/api/occupancy';
// export const ABC_BASE_PATH = 'http://localhost:4000/api/Abc';
// export const ENERGENCYWORK_BASE_PATH = 'http://localhost:4000/api/emergencywork';
// export const OCCUPANCYPERMIT_BASE_PATH = 'http://localhost:4000/api/OccupancyPermitPayment';
// export const REVENUE_BASE_PATH = 'http://localhost:4000/api/revenue';
// export const OUTLOOK_BASE_PATH = 'https://permit.milestonediscovery.com/api/email';
 
 
 
 
 
// export const APP_BASE_PATH = 'https://api.milestonediscovery.com/';
// export const LOGIN_BASE_PATH = 'https://api.milestonediscovery.com/api/login';
// export const USERS_BASE_PATH = 'https://api.milestonediscovery.com/api/users';
// export const CONTRACTORS_BASE_PATH = 'https://api.milestonediscovery.com/api/contractors';
// export const EXCEL_BASE_PATH = 'https://api.milestonediscovery.com/api/excel';
// export const ITEM_BASE_PATH = 'https://api.milestonediscovery.com/api/items';
// export const EMAIL_BASE_PATH = 'https://api.milestonediscovery.com/api/email';
// export const STATUS_BASE_PATH = 'https://api.milestonediscovery.com/api/status';
// export const RECORDS_BASE_PATH = 'https://api.milestonediscovery.com/api/records';
// export const INVOICE_BASE_PATH = 'https://api.milestonediscovery.com/api/invoice';
// export const EXCEPTION_BASE_PATH = 'https://api.milestonediscovery.com/api/Exception';
// export const CREW_BASE_PATH = 'https://api.milestonediscovery.com/api/crew';
// export const OCCUPANCY_BASE_PATH = 'https://api.milestonediscovery.com/api/occupancy';
// export const ABC_BASE_PATH = 'https://api.milestonediscovery.com/api/Abc';
// export const ENERGENCYWORK_BASE_PATH = 'https://api.milestonediscovery.com/api/emergencywork';
// export const OCCUPANCYPERMIT_BASE_PATH = 'https://api.milestonediscovery.com/api/OccupancyPermitPayment';
// export const REVENUE_BASE_PATH = 'https://api.milestonediscovery.com/api/revenue';
// export const OUTLOOK_BASE_PATH = 'https://permit.milestonediscovery.com/api/email';
 
 
 
 
export const APP_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/';
export const LOGIN_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/login';
export const USERS_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/users';
export const CONTRACTORS_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/contractors';
export const EXCEL_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/excel';
export const ITEM_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/items';
export const EMAIL_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/email';
export const STATUS_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/status';
export const RECORDS_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/records';
export const INVOICE_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/invoice';
export const EXCEPTION_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/Exception';
export const CREW_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/crew';
export const OCCUPANCY_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/occupancy';
export const ABC_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/Abc';
export const ENERGENCYWORK_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/emergencywork';
export const OCCUPANCYPERMIT_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/OccupancyPermitPayment';
export const REVENUE_BASE_PATH = 'https://api.fmccpatch.fortmyer.us/api/revenue';
export const OUTLOOK_BASE_PATH = 'https://permit.fmccpatch.fortmyer.us/api/email';